<template>
  <div>
    <NewFormBuilder></NewFormBuilder>
  </div>
</template>
<script>
import NewFormBuilder from "../../components/formBuilders/NewFormBuilder.vue";
export default {
  components: {
    NewFormBuilder
  },
  data() {
    return {};
  }
};
</script>
<style lang="scss" scoped>
</style>