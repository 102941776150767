<template>
  <div class="template-editor" v-if="editor">
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div class="menubar">
        <el-tooltip class="el-tooltips" content="Bold">
        <button
          type="button"
          class="menubar__button"
          :class="{ 'is-active': isActive.bold() }"
          @click="commands.bold"
        >
          <img src="@/assets/img/icons/bold.svg" height="15" witdth="15" />
        </button>
        </el-tooltip>
        <el-tooltip class="el-tooltips" content="Italic">
        <button
          type="button"
          class="menubar__button"
          :class="{ 'is-active': isActive.italic() }"
          @click="commands.italic"
        >
          <img src="@/assets/img/icons/italic.svg" height="15" witdth="15" />
        </button>
        </el-tooltip>
        <el-tooltip class="el-tooltips" content="Strike">
        <button
          type="button"
          class="menubar__button"
          :class="{ 'is-active': isActive.strike() }"
          @click="commands.strike"
        >
          <img src="@/assets/img/icons/strike.svg" height="15" witdth="15" />
        </button>
        </el-tooltip>
        <el-tooltip class="el-tooltips" content="Underline">
        <button
          type="button"
          class="menubar__button"
          :class="{ 'is-active': isActive.underline() }"
          @click="commands.underline"
        >
          <img src="@/assets/img/icons/underline.svg" height="15" witdth="15" />
        </button>
        </el-tooltip>
        <el-tooltip class="el-tooltips" content="Paragraph">
        <button
          type="button"
          class="menubar__button"
          :class="{ 'is-active': isActive.paragraph() }"
          @click="commands.paragraph"
        >
          <img src="@/assets/img/icons/paragraph.svg" height="15" witdth="15" />
        </button>
        </el-tooltip>
        <el-tooltip class="el-tooltips" content="Heading1">
            <button
              type="button"
              class="menubar__button"
              :class="{ 'is-active': isActive.heading({ level: 1 }) }"
              @click="commands.heading({ level: 1 })"
            >
              H1
            </button>
        </el-tooltip>
        <el-tooltip class="el-tooltips" content="Heading2">
        <button
          type="button"
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 2 }) }"
          @click="commands.heading({ level: 2 })"
        >
          H2
        </button>
        </el-tooltip>
        <el-tooltip class="el-tooltips" content="Heading3">
        <button
          type="button"
          class="menubar__button"
          :class="{ 'is-active': isActive.heading({ level: 3 }) }"
          @click="commands.heading({ level: 3 })"
        >
          H3
        </button>
        </el-tooltip>
        <el-tooltip class="el-tooltips" content="Bullet_list">
        <button
          type="button"
          class="menubar__button"
          :class="{ 'is-active': isActive.bullet_list() }"
          @click="commands.bullet_list"
        >
          <img src="@/assets/img/icons/ul.svg" height="15" witdth="15" />
        </button>
        </el-tooltip>
        <el-tooltip class="el-tooltips" content="Ordered list">
        <button
          type="button"
          class="menubar__button"
          :class="{ 'is-active': isActive.ordered_list() }"
          @click="commands.ordered_list"
        >
          <img src="@/assets/img/icons/ol.svg" height="15" witdth="15" />
        </button>
      </el-tooltip>
      <el-tooltip class="el-tooltips" content="Blockquote">
        <button
          type="button"
          class="menubar__button"
          :class="{ 'is-active': isActive.blockquote() }"
          @click="commands.blockquote"
        >
          <img src="@/assets/img/icons/quote.svg" height="15" witdth="15" />
        </button>
        </el-tooltip>
        <el-tooltip class="el-tooltips" content="Code block">
        <button
          type="button"
          class="menubar__button"
          :class="{ 'is-active': isActive.code_block() }"
          @click="commands.code_block"
        >
          <img src="@/assets/img/icons/code.svg" height="15" witdth="15" />
        </button>
        </el-tooltip>
        <el-tooltip class="el-tooltips" content="Horizontal Rule">
        <button
          type="button"
          class="menubar__button"
          @click="commands.horizontal_rule"
        >
          <img src="@/assets/img/icons/hr.svg" height="15" witdth="15" />
        </button>
        </el-tooltip>
        <el-tooltip class="el-tooltips" content="Undo">
        <button type="button" class="menubar__button" @click="commands.undo">
          <img src="@/assets/img/icons/undo.svg" height="15" witdth="15" />
        </button>
        </el-tooltip>
        <el-tooltip class="el-tooltips" content="Redo">
        <button type="button" class="menubar__button" @click="commands.redo">
          <img src="@/assets/img/icons/redo.svg" height="15" witdh="15" />
        </button>
        </el-tooltip>
      </div>
    </editor-menu-bar>
  <div class="paragraphScroll">
    <editor-content
      class="page"
      v-loading="loading"
      element-loading-text="Preparing the editor..."
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      :editor="editor"
      
    />
  </div>
   
  </div>
</template>

<script>
// import constants from "@/config/constants";

import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  Blockquote,
  CodeBlock,
  HardBreak,
  Heading,
  HorizontalRule,
  OrderedList,
  BulletList,
  ListItem,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
} from "tiptap-extensions";

export default {
  nmae: "contentBlocks-TextEditorContent",
  components: {
    EditorMenuBar,
    EditorContent,
  },
  props: ["editorData"],
  mixins: [],
  data() {
    return {
      editor: null,
      query: null,
      suggestionRange: null,
      filteredUsers: [],
      mentionsFromFlow: [],
      navigatedUserIndex: 0,
      popup: null,
      html: "",
      content: "",
      loading: false,
      form: {
        name: "",
        description: "",
        template_body: "",
        template_fields: {},
      },
    };
  },

  async mounted() {
    this.setupEditor();
    // this.setForm();
    this.loading = false;
  },
  //   beforeDestroy() {
  //     if (this.editor) {
  //       this.editor.destroy();
  //     }
  //   },

  computed: {},
  methods: {
    setupEditor() {
      this.showEditor = false;

      let options = {
        extensions: [
          new HardBreak(),
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new HorizontalRule(),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Code(),
          new Bold(),
          new Italic(),
        ],
        onUpdate: ({ getHTML }) => {
          let content = getHTML();
          this.$emit("update:editorData", content);
        },
      };

      // this.editor.setOptions(options);
      this.editor = new Editor(options);
      this.editor.setContent(this.editorData);
      this.showEditor = true;
    },

    destroyPopup() {
      if (this.popup) {
        this.popup = null;
      }
    },

    setForm() {
      this.setEmptyForm();
    },

    setEmptyForm() {
      this.editor.setContent(this.editorData);
    },

    async submitForm() {
      this.$emit("template-done", this.form.template_body);
    },
  },
};
</script>

<style scoped lang="scss">
.offer-letter-generator {
  .form-group {
    margin-bottom: 25px;
  }
}

.el-input__inner {
  height: 45px;
  background: #f4f9fe;
  box-shadow: 0px 1px 2px #ddebfa;
  border: none;
  border-radius: 5px;
  font-size: 1.1em;
  letter-spacing: 0.5px;

  &:focus {
    border-color: transparent;
  }
  label {
    color: #1b487e;
    font-size: 0.9em;
    letter-spacing: 0.5px;
    margin-bottom: 5px;
    display: inline-block;
  }
}

.template-editor {
  margin-top: 35px;
}
$color-black: #000000;
$color-white: #ffffff;
$color-grey: #dddddd;
.mention {
  background: rgba($color-black, 0.1);
  color: rgba($color-black, 0.6);
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 5px;
  padding: 0.2rem 0.5rem;
  white-space: nowrap;
}
.mention-suggestion {
  color: rgba($color-black, 0.6);
}
.suggestion-list {
  padding: 0.2rem;
  border: 2px solid rgba($color-black, 0.1);
  font-size: 0.8rem;
  font-weight: bold;
  &__no-results {
    padding: 0.2rem 0.5rem;
  }
  &__item {
    border-radius: 5px;
    padding: 0.2rem 0.5rem;
    margin-bottom: 0.2rem;
    cursor: pointer;
    &:last-child {
      margin-bottom: 0;
    }
    &.is-selected,
    &:hover {
      background-color: rgba($color-white, 0.2);
    }
    &.is-empty {
      opacity: 0.5;
    }
  }
}
.tippy-box[data-theme~="dark"] {
  background-color: $color-black;
  padding: 0;
  font-size: 1rem;
  text-align: inherit;
  color: $color-white;
  border-radius: 5px;
}

.menubar {
  background: #e8eaea;
  padding: 10px;
  transition: visibility 0.2s 0.4s, opacity 0.2s 0.4s;

  &.is-hidden {
    visibility: hidden;
    opacity: 0;
  }

  &.is-focused {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.2s, opacity 0.2s;
  }

  &__button {
    font-weight: bold;
    display: inline-flex;
    background: transparent;
    border: 0;
    color: #000000;
    padding: 0.2rem 0.5rem;
    margin-right: 0.2rem;
    border-radius: 3px;
    cursor: pointer;

    &:hover {
      background-color: rgba(#000000, 0.05);
    }

    &.is-active {
      background-color: rgba(#000000, 0.1);
    }
  }

  span#{&}__button {
    font-size: 13.3333px;
  }
}

.basic-form {
  margin-top: 10px;
  padding: 10px;
  .form-group {
    margin-bottom: 20px;
    label {
      color: #1b487e;
      font-size: 0.9em;
      letter-spacing: 0.5px;
      margin-bottom: 5px;
      display: inline-block;
    }

    input {
      height: 50px;
      background: #f4f9fe;
      box-shadow: 0px 1px 2px #ddebfa;
      border: none;
      border-radius: 5px;
      font-size: 1.1em;
      letter-spacing: 0.5px;

      &:focus {
        border-color: transparent;
      }
    }
  }
}

.template-editor {
  border: solid 1px #000000;
  border-radius: 4px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}
.page {
  min-height: 200px !important;
  .ProseMirror {
    border: none !important;
    outline: none !important;
    min-height: 600px !important;
  }
  .ProseMirror.ProseMirror-focused {
    border: none !important;
    outline: none !important;
    min-height: 600px !important;
  }
}
.editor {
  position: relative;
  max-width: 200rem;
  margin: 0 auto 5rem auto;

  &__content {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;

    * {
      caret-color: currentColor;
    }

    pre {
      padding: 0.7rem 1rem;
      border-radius: 5px;
      background: $color-black;
      color: $color-white;
      font-size: 0.8rem;
      overflow-x: auto;

      code {
        display: block;
      }
    }

    p code {
      padding: 0.2rem 0.4rem;
      border-radius: 5px;
      font-size: 0.8rem;
      font-weight: bold;
      background: rgba($color-black, 0.1);
      color: rgba($color-black, 0.8);
    }

    ul,
    ol {
      padding-left: 1rem;
    }

    li > p,
    li > ol,
    li > ul {
      margin: 0;
    }

    a {
      color: inherit;
    }

    blockquote {
      border-left: 3px solid rgba($color-black, 0.1);
      color: rgba($color-black, 0.8);
      padding-left: 0.8rem;
      font-style: italic;

      p {
        margin: 0;
      }
    }

    img {
      max-width: 100%;
      border-radius: 3px;
    }

    table {
      border-collapse: collapse;
      table-layout: fixed;
      width: 100%;
      margin: 0;
      overflow: hidden;

      td,
      th {
        min-width: 1em;
        border: 2px solid $color-grey;
        padding: 3px 5px;
        vertical-align: top;
        box-sizing: border-box;
        position: relative;
        > * {
          margin-bottom: 0;
        }
      }

      th {
        font-weight: bold;
        text-align: left;
      }

      .selectedCell:after {
        z-index: 2;
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(200, 200, 255, 0.4);
        pointer-events: none;
      }

      .column-resize-handle {
        position: absolute;
        right: -2px;
        top: 0;
        bottom: 0;
        width: 4px;
        z-index: 20;
        background-color: #adf;
        pointer-events: none;
      }
    }

    .tableWrapper {
      margin: 1em 0;
      overflow-x: auto;
    }

    .resize-cursor {
      cursor: ew-resize;
      cursor: col-resize;
    }
  }
}

.footer {
  padding: 10px 20px 20px;
  text-align: right;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.error {
  color: #f44;
  margin: 0;
  line-height: 20px;
  font-size: 13px;
  margin-top: 4px;
}

.el-dialog__footer {
  margin-top: 30px;
}

.emp-signature {
  padding: 10px;
  margin-top: 10px;
}
.paragraphScroll {
  height: 200px;
  overflow-y: scroll;
  overflow-x: scroll;
  scrollbar-width: thin;
  border-top: 1px ridge;
  border-left: 1px ridge;
  border-bottom: 1px ridge;
  margin-right: 5px;
}
.paragraphScroll::-webkit-scrollbar {
  width: 0.5em;
}
.paragraphScroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  background: #e1e1e1;
  -webkit-box-shadow: inset 0 0 6px rgba(172, 169, 169, 0.9);
}
</style>
